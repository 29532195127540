import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from './routes';
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import 'sweetalert2/src/sweetalert2.scss'

function App() {
  return (
    <div className="App">
      <Router>
        <MyRoutes />
        <ToastContainer position='top-start' />
      </Router>
    </div>
  );
}

export default App;
