import { useEffect, useState } from "react";
import { AddMedApi, CategoryApi } from "../services/services"
import { Table } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useNavigate, Link } from "react-router-dom";

export default function AllCatalog() {
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function getList() {
        setLoading(true)
        CategoryApi("get").then(resp => {
            setData(resp.data)
            AddMedApi("get", {}, "").then(medResp => {
                if (medResp.data.length) {
                    setList(medResp.data.map(med => ({ ...med, category: resp.data.find(c => c.id == med.category) })).sort((a,b) => a.category.id - b.category.id))
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Image</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map(d => (
                            <tr>
                                <td>{d.name.toUpperCase()}</td>
                                <td>{d.category.name}</td>
                                <td>
                                    <a href={`${process.env.REACT_APP_API_LOCAL}${d.image}`}>
                                        <img src={`${process.env.REACT_APP_API_LOCAL}${d.image}`} height={180} />
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        
        </>
    )
}