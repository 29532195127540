import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import { Form, Button, Row, Col, Modal, Table } from 'react-bootstrap';
import { AddMedApi } from '../services/services';
import Loader from "../common/Loader"
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

function AddMedicine() {
    const [list, setList] = useState([])
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [formData, setFormData] = useState({
        name: '',
        mrp: '',
        image: ''
    });
    const navigate = useNavigate();
    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        AddMedApi("get", {}, searchParams.get("id")).then(resp => {
            setList(resp.data)
            setLoading(false)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            } setLoading(false)

        })
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name == 'image') {
            setFormData(prevState => ({
                ...prevState,
                [name]: e.target.files[0]
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true)
        let postData = new FormData();
        postData.append("category", searchParams.get("id"))
        postData.append("name", formData.name)
        postData.append("mrp", formData.mrp)
        postData.append("box_price", formData.boxPrice)
        postData.append("selling_price", formData.sellingPrice)
        postData.append("image", formData.image)
        postData.append("usage", formData.usage)
        AddMedApi("create", postData).then(resp => {
            setLoading(false)
            getList()
            Swal.fire("Medicine added successfully.", "", "success")
            setFormData({
                mrp: '',
                name: '',
                image: ''
            })
            setShowModal(false)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    };

    function handleDeleteMed(id) {
        Swal.fire({
            text: 'Do you want to delete the medicine?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                setLoading(true)
                AddMedApi("delete", "", id).then(resp => {
                    Swal.fire("Medicine deleted successfully.", "", "success")
                    setLoading(false)
                    getList()
                }).catch(err => {
                    if (err.response.status == 401) {
                        Swal.fire("Kindly log in to continue.", "", "warning")
                        navigate("/")
                    } else {
                        Swal.fire("An error occured.", "", "error")
                    }
                })
            }
        })
    }

    function handleClickEditMed(med) {
        setEdit(true)
        setShowModal(true)
        setFormData({
            id: med.id,
            mrp: med.mrp,
            name: med.name,
            imageUrl: med.image,
            usage: med.usage
        })
    }

    function handleEditMed(e) {
        e.preventDefault();
        let postData = new FormData();
        postData.append("category", searchParams.get("id"))
        postData.append("name", formData.name)
        postData.append("mrp", formData.mrp)
        postData.append("usage", formData.usage)
        if (formData.image) {
            postData.append("image", formData.image)
        }
        setLoading(true)
        AddMedApi("put", postData, formData.id).then(resp => {
            setLoading(false)
            getList()
            // Swal.fire("Medicine updated successfully.", "", "success")
            setFormData({
                mrp: '',
                name: '',
                image: ''
            })
            setShowModal(false)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    return (
        <div>
            {loading && <Loader />}
            <div className="row">
                <div className="col">
                    <h2>Category - {searchParams.get("name")}</h2>
                </div>
                <div className="col-auto">
                    <Button variant='primary' onClick={() => {
                        setEdit(false)
                        setShowModal(true)
                        setFormData({})
                    }}>
                        Add Medicine
                    </Button>
                </div>
            </div>
            <hr />

            <div className="row">
                <Col md={12} sm={12} lg={12}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((med, i) => (
                                    <tr>
                                        <td>{med.name.toUpperCase()}</td>
                                        <td>
                                            <a href={`${process.env.REACT_APP_API_LOCAL}${med.image}`}>
                                                <img src={`${process.env.REACT_APP_API_LOCAL}${med.image}`} height={130} />
                                            </a>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-between">
                                                <Button size='sm' onClick={() => handleClickEditMed(med)}>
                                                    <FaPencilAlt />
                                                </Button>

                                                <Button size='sm' variant="danger" className='ms-2' onClick={() => handleDeleteMed(med.id)}>
                                                    <FaTrash />
                                                </Button>

                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </div>

            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        <h5>{edit ? "Edit" : "Add New"} Medicine</h5>
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={edit ? handleEditMed : handleSubmit}>
                    <Modal.Body>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                className='mb-3'
                                placeholder="Name"
                                name="name"
                                required
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        

                        <Form.Group controlId="image">
                            <Form.Label>Image</Form.Label>
                            <Form.Control
                                type="file"
                                name="image"
                                className='mb-3'
                                accept='image/*'
                                required={!edit}
                                onChange={handleChange}
                            />
                            {
                                edit && (
                                    <>
                                        <p>Current Image:</p>
                                        <a href={`${process.env.REACT_APP_API_LOCAL}${formData.imageUrl}`} target='_blank'>
                                            <img src={`${process.env.REACT_APP_API_LOCAL}${formData.imageUrl}`} height={150} />
                                        </a>
                                    </>
                                )
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="button" onClick={() => setShowModal(false)}>Cancel</Button>
                        <Button variant="primary" type="submit" className='px-4'>{edit ? "Submit" : "Add Medicine"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default AddMedicine