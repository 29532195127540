import { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { DashbaordApi } from "../services/services";
import Loader from "../common/Loader"
import Swal from 'sweetalert2';
import { useNavigate, Link } from "react-router-dom";

const Dashboard = () => {
    let [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        DashbaordApi().then(resp => {
            setLoading(false)
            setData(resp.data)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }, [])

    return <>
        {loading && <Loader />}
        <div className="row">
            <div class="dashboard-widget-card col-xs-3 col-sm-3 col-md-3">
                <Card>
                    <Card.Body>
                        <h5>Total Visits for Today</h5>
                        <h6>{data.today_count}</h6>
                    </Card.Body>
                </Card>
            </div>

            <div class="dashboard-widget-card col-xs-3 col-sm-3 col-md-3">
                <Card>
                    <Card.Body>
                        <h5>Pending Visits for Today</h5>
                        <h6>{data.pending_count}</h6>
                    </Card.Body>
                </Card>
            </div>

            <div class="dashboard-widget-card col-xs-3 col-sm-3 col-md-3">
                <Card>
                    <Card.Body>
                        <h5>Completed Visits for Today</h5>
                        <h6>{data.completed_count}</h6>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>
}

export default Dashboard