import Swal from "sweetalert2";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { RiHealthBookFill } from "react-icons/ri";
import { GrCatalog } from "react-icons/gr";
import { FaHome, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { FiUserCheck } from "react-icons/fi";
import { useState } from "react";

const Sidebar = ({className}) => {
    const navigate = useNavigate();
    const location = useLocation();

    function logout() {
        Swal.fire({
            text: 'Do you want to logout?',
            icon: 'warning',
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                localStorage.removeItem("token")
                navigate("/")
            }
        })
    }
    return (
        <nav className={className}>
            <div className="logo">
                <img src="/logolp.png" alt="Logo" />
                <span>Levotic Pharma</span>
            </div>
            <ul className="nav-links">
                <li>
                    <NavLink to="/dashboard" className={location.pathname.includes("dashboard") ? "active-link" : ""}>
                        <FaHome /> <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/entities" className={location.pathname.includes("entities") ? "active-link" : ""}>
                        <RiHealthBookFill /> <span>Health Care Entities</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/catalog" className={location.pathname.includes("catalog") || location.pathname.includes("add-medicine") ? "active-link" : ""}>
                        <GrCatalog /> <span>Catalog</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/mr-list" className={location.pathname.includes("mr-list") || location.pathname.includes("mr-schedule") ? "active-link" : ""}>
                        <FaUser /> <span>MR List</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/attendance" className={location.pathname.includes("attendance") ? "active-link" : ""}>
                        <FiUserCheck /> <span>Attendance</span>
                    </NavLink>
                </li>
            </ul>
            <div className="logout-btn">
                <Link to="#" onClick={() => logout()}>
                    <FaSignOutAlt /> Logout
                </Link>
            </div>
        </nav>
    );
};

export default Sidebar