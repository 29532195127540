import { useEffect } from "react"
import { useState } from "react"
import { Col, Container, Form, Row, Table } from "react-bootstrap"
import { AttendanceApi, offset, view_datetime } from "../services/services"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import PaginationBasic from '../common/Pagination';
import Loader from "../common/Loader"

function Attendance() {
    let [data, setData] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();
    const today = new Date();

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        setLoading(true)
        AttendanceApi("date=" + formattedDate).then(resp => {
            setLoading(false)
            setTotalPage(resp.data.count / offset)
            setData(resp.data.results)
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }, [selectedDate])

    return (
        <>
            {loading && <Loader />}
            <Container fluid>
                <Row>
                    <div className="col">
                        <h3>Attendance</h3>
                    </div>
                </Row>
                <hr />
                <Row>
                    <Col md={3} sm={6} xs={6}>
                        <Form.Label>Showing record for</Form.Label>
                        <DatePicker
                            maxDate={today}
                            selected={selectedDate}
                            onSelect={handleDateChange}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-YYYY"
                            onChangeRaw={(e) => e.preventDefault()}
                            customInput={
                                <div className="input-container">
                                    <input readOnly value={selectedDate ? selectedDate.toLocaleDateString() : ''} className="form-control" />
                                    <FaCalendarAlt className="calendar-icon" />
                                </div>
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} className="mt-3">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>MR Name</th>
                                    <th>MR ID</th>
                                    <th>Username</th>
                                    <th>Phone</th>
                                    <th>Checkin Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.mr.user.first_name} {d.mr.user.last_name}</td>
                                                <td>{d.mr.employee_id}</td>
                                                <td>{d.mr.user.username}</td>
                                                <td>{d.mr.contact_number}</td>
                                                <td>{view_datetime(d.datetime)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <PaginationBasic currentPage={activePage} setActive={setActivePage} totalPages={totalPage} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Attendance