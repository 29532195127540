import { Button, Form, Modal, Table } from "react-bootstrap";
import { CategoryApi } from "../services/services";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loader from "../common/Loader"
import { useNavigate, Link } from "react-router-dom";
function Catalog() {
    let [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getList()
    }, [])

    const [showModal, setShowModal] = useState(false);
    const [category, setCategory] = useState([]);
    const [data, setData] = useState([]);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    function getList() {
        setLoading(true)
        CategoryApi("get").then(resp => {
            setLoading(false)
            setData(resp.data)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    function handleAddCategory(e) {
        e.preventDefault()
        setLoading(true)
        CategoryApi("create", category).then(resp => {
            setLoading(false)
            setCategory({});
            handleClose();
            Swal.fire("Categoey added successfully.", "", "success")
            getList()
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    return (
        <div>
            {loading && <Loader />}
            <div className="row">
                <div className="col">
                    <h2>Catalog</h2>
                </div>
                <div className="col-auto">
                    <Button variant="primary" onClick={handleShow}>
                        Add New Category
                    </Button>
                    <Link to={"all/"}>
                        <Button variant="primary" className="ms-3">
                            View All Catalog
                        </Button>
                    </Link>
                </div>
            </div>
            <hr />
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((cat, i) => (
                            <tr key={i}>
                                <td>{cat.name}</td>
                                <td>
                                    <Link className="btn btn-primary btn-sm" to={`/add-medicine?id=${cat.id}&name=${cat.name}`}>
                                        Add/View Medicine
                                    </Link>
                                    {/* <Button variant="primary" size="sm">
                                        Add Medicine
                                    </Button> */}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Category</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleAddCategory}>
                    <Modal.Body>
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter category name"
                            value={category.name}
                            required
                            onChange={(e) => setCategory({ name: e.target.value })}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Add Category
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )

}

export default Catalog;