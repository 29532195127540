import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { MrListApi, offset } from '../services/services';
import Swal from 'sweetalert2';
import { useNavigate, Link } from "react-router-dom";
import PaginationBasic from '../common/Pagination';
import Loader from "../common/Loader"

const MRListing = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    let [loading, setLoading] = useState(false)
    const [mrList, setMRList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [newMR, setNewMR] = useState({ username: '', firstname: '', lastname: '', password: '', contactNumber: '' });

    useEffect(() => {
        getMrList()
    }, [activePage])

    const handleShow = () => {
        setEdit(false)
        setShowModal(true)
        setNewMR({})
    };
    const handleClose = () => setShowModal(false);

    const getMrList = () => {
        setLoading(true)
        MrListApi("get", {}, (activePage - 1) * offset).then(resp => {
            setLoading(false)
            if (resp.data.count) {
                setMRList(resp.data.results)
                setTotalPage(resp.data.count / offset)
            }
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    const handleAddMR = (e) => {
        e.preventDefault();
        setLoading(true)
        MrListApi('create', newMR).then(resp => {
            setLoading(false)
            Swal.fire(`MR added successfully.`, "", "success")
            setNewMR({ username: '', firstname: '', lastname: '', password: '', contactNumber: '' });
            handleClose();
            getMrList()
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    };

    const handleEditMR = (e) => {
        e.preventDefault();
        setLoading(true)
        MrListApi('put', newMR).then(resp => {
            setLoading(false)
            Swal.fire(`MR updated successfully.`, "", "success")
            setNewMR({ username: '', firstname: '', lastname: '', password: '', contactNumber: '', id: '' });
            handleClose();
            getMrList()
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else if (err.response.data.message) {
                Swal.fire(err.response.data.message, "", "error")
            } else {
                Swal.fire("Error", "", "error")

            }
        })
    };

    const handleDeleteMR = (id) => {
        Swal.fire({
            text: 'Do you want to delete the MR?',
            icon: 'warning',
            confirmButtonText: 'Yes!',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                setLoading(true)
                MrListApi('delete', id).then(resp => {
                    setLoading(false)
                    Swal.fire("MR deleted successfully.", "", "success")
                    getMrList()
                }).catch(err => {
                    if (err.response.status == 401) {
                        Swal.fire("Kindly log in to continue.", "", "warning")
                        navigate("/")
                    } else {
                        Swal.fire("An error occured.", "", "error")
                    }
                })
            }
        })

    };

    return (
        <div>
            {loading && <Loader />}
            <div className="row">
                <div className="col">
                    <h2>Medical Representatives</h2>
                </div>
                <div className="col-auto">
                    <Button variant="success" onClick={handleShow}>
                        Add New MR
                    </Button>
                </div>
            </div>
            <hr />

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Contact Number</th>
                        <th>Employee ID</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        mrList.map((mr) => (
                            <tr key={mr.id}>
                                <td>{mr.user.first_name} {mr.user.last_name}</td>
                                <td>{mr.user.username}</td>
                                <td>{mr.contact_number}</td>
                                <td>{mr.employee_id}</td>
                                <td>
                                    <Button className='me-2' variant="primary" onClick={() => {
                                        setEdit(true)
                                        setShowModal(true)
                                        setNewMR({ username: mr.user.username, firstname: mr.user.first_name, lastname: mr.user.last_name, password: '', contactNumber: mr.contact_number, employeeId: mr.employee_id, id: mr.id })
                                    }}>
                                        Edit
                                    </Button>

                                    <Link to={`/mr-schedule/${mr.user.id}`} className='btn btn-primary'>
                                        View Reporting
                                    </Link>

                                    <Button className='ms-2' variant="danger" onClick={() => handleDeleteMR(mr.id)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <PaginationBasic currentPage={activePage} setActive={setActivePage} totalPages={totalPage} />

            <Modal show={showModal} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{edit ? "Edit" : "Add New"} MR</Modal.Title>
                </Modal.Header>
                <Form onSubmit={edit ? handleEditMR : handleAddMR}>
                    <Modal.Body>
                        <Form.Group controlId="formUsername" className='mb-3'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={newMR.username}
                                required
                                onChange={(e) => setNewMR({ ...newMR, username: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formFirstname" className='mb-3'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter password"
                                required={!edit}
                                value={newMR.password}
                                onChange={(e) => setNewMR({ ...newMR, password: e.target.value })}
                            />
                            {
                                edit &&
                                <small>Leave field blank if you do not wish to update</small>
                            }
                        </Form.Group>
                        <div className='d-flex mb-3'>
                            <Form.Group controlId="formFirstname" className='w-50 pe-2'>
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter firstname"
                                    required
                                    value={newMR.firstname}
                                    onChange={(e) => setNewMR({ ...newMR, firstname: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="formLastname" className='w-50'>
                                <Form.Label>Lastname</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter lastname"
                                    required
                                    value={newMR.lastname}
                                    onChange={(e) => setNewMR({ ...newMR, lastname: e.target.value })}
                                />
                            </Form.Group>
                        </div>
                        <Form.Group controlId="formContactNumber" className='mb-3'>
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter contact number"
                                required
                                value={newMR.contactNumber}
                                onChange={(e) => setNewMR({ ...newMR, contactNumber: e.target.value })}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" className='px-4'>
                            {edit ? "Submit" : "Add MR"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default MRListing;
