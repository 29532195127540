import { Route, Routes } from 'react-router-dom';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Sidebar from './common/Sidebar';
import MRListing from './views/MRList';
import Entities from './views/Entities';
import NotFound from './common/NotFound';
import Visits from './views/Visits';
import Catalog from './views/Catalog';
import AddMedicine from './views/AddMedicine';
import Attendance from './views/Attendance';
import Schedules from './views/Schedules';
import AllCatalog from './views/AllCatalog';
import { useState } from 'react';
import { FaBars } from 'react-icons/fa';


const MyRoutes = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };


    const withSidebar = (Component) => {
        return <div className="app-container">
            <Sidebar className={`sidebar ${sidebarOpen ? 'open' : ''}`} />
            <div className="content">
                <button className="toggle-button" onClick={toggleSidebar}>
                    <FaBars />
                </button>
                <Component />
            </div>
        </div>
    }

    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/dashboard" exact element={withSidebar(Dashboard)} />
            <Route path="/catalog" exact element={withSidebar(Catalog)} />
            <Route path="/catalog/all/" exact element={withSidebar(AllCatalog)} />
            <Route path="/entities" exact element={withSidebar(Entities)} />
            <Route path="/mr-list" exact element={withSidebar(MRListing)} />
            <Route path="/mr-visits" exact element={withSidebar(Visits)} />
            <Route path="/add-medicine" exact element={withSidebar(AddMedicine)} />
            <Route path="/attendance" exact element={withSidebar(Attendance)} />
            <Route path="/mr-schedule/:id" exact element={withSidebar(Schedules)} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default MyRoutes;
