import Swal from "sweetalert2";
import { BsCheck2 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { useEffect, useRef, useState } from "react";
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form, Table, Container, Row, Col, Modal } from "react-bootstrap";
import { AddMedApi, OrderApi, VisitsApi, offset, view_datetime } from "../services/services";
import PaginationBasic from '../common/Pagination';
import { useNavigate, useParams } from "react-router-dom"
import Loader from "../common/Loader";

function Schedules() {
    const [loading, setLoading] = useState(false);
    const [orderModal, setOrderModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [meds, setMeds] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [medShowModal, setMedShowModal] = useState(false);
    const [medShowData, setMedShowData] = useState([])
    const navigate = useNavigate();

    const today = new Date();
    const urlParams = useParams();

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        getMedicineList();
    }, [])

    useEffect(() => {
        getMeetings()
    }, [selectedDate])

    function getMeetings() {
        setLoading(true)
        const formattedDate = selectedDate.toISOString().split('T')[0];
        VisitsApi(false, {}, `date=${formattedDate}&mr=${urlParams.id}&offset=${(activePage - 1) * offset}`).then(resp => {
            setTableData(resp.data.results)
            setLoading(false)
            setTotalPage(resp.data.count / offset)
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    function getMedicineList() {
        setLoading(true)
        AddMedApi("get", {}, "").then(resp => {
            setLoading(false)
            setMeds(resp.data)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    function handleOrderClick(visit) {
        setOrderData(visit.order)
        setOrderModal(true)
    }

    function dismissOrderModal(process = false) {
        if (process) {
            // OrderApi(orderData[0].visit, { "status": "processed" }).then(resp => {
            //     Swal.fire("MR Order marked as processed.", "", "success")
            //     setOrderModal(false)
            //     setOrderData([])
            // }).catch(err => {
            //     setLoading(false)
            //     if (err.response.status == 401) {
            //         Swal.fire("Kindly log in to continue.", "", "warning")
            //         navigate("/")
            //     } else {
            //         Swal.fire("An error occured.", "", "error")
            //     }
            // })
        } else {
            setOrderModal(false)
            setOrderData([])
        }
    }

    return <div>
        {loading && <Loader />}
        <Container fluid className="daily-call-reporting">
            <Row>
                <div className="col">
                    <h3>MR Daily Call Reporting</h3>
                </div>
            </Row>
            <Row>
                <Col md={3} sm={6} xs={6}>
                    <Form.Label>Showing record for</Form.Label>
                    <DatePicker
                        maxDate={today}
                        selected={selectedDate}
                        onSelect={handleDateChange}
                        onChange={handleDateChange}
                        dateFormat="dd-MM-YYYY"
                        onChangeRaw={(e) => e.preventDefault()}
                        customInput={
                            <div className="input-container">
                                <input readOnly value={selectedDate ? selectedDate.toLocaleDateString() : ''} className="form-control" />
                                <FaCalendarAlt className="calendar-icon" />
                            </div>
                        }
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={12}>
                    <Table striped bordered responsive>
                        <thead>
                            <tr>
                                {/* <th>KYC</th> */}
                                <th>Doctor Name</th>
                                <th>Entity Type</th>
                                <th>Visit Time</th>
                                <th>Area</th>
                                <th>Status</th>
                                <th>Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.healthcare_entity.name}</td>
                                    <td>{data.healthcare_entity.entity_type.toUpperCase()}</td>
                                    <td>{view_datetime(data.visit_checkin)}</td>
                                    <td>
                                        {data.healthcare_entity.area}
                                    </td>
                                    <td>{
                                        data.completed ? <>
                                            <Button variant="success" size="sm">Completed</Button>
                                        </>
                                            :
                                            selectedDate.toISOString().split('T')[0] == today.toISOString().split('T')[0] ? <Button variant="secondary" size="sm">Pending</Button>
                                                :
                                                <Button variant="danger" size="sm">Missed</Button>
                                    }</td>
                                    <td>
                                        <Button className="ms-3" size="sm" onClick={() => handleOrderClick(data)}>View Order</Button>
                                        <Button className="ms-3" size="sm" onClick={() => {
                                            setMedShowModal(true)
                                            setMedShowData(data.meds_shown)
                                        }}>Medicines Shown</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <PaginationBasic currentPage={activePage} setActive={setActivePage} totalPages={totalPage} />
                </Col>
            </Row>
        </Container>

        <Modal show={orderModal} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <h4>Order Details </h4>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Medicine</th>
                            <th>Box/Piece Quantity</th>
                            <th>Strip Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderData && orderData.map((odr, i) => {
                                const med = meds.find(m => m.id == odr.medicine)
                                return (
                                    <tr key={i}>
                                        <td>{med.name}</td>
                                        <td>{odr.box_quantity}</td>
                                        <td>{odr.strip_quantity}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => dismissOrderModal(false)} variant="secondary">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={medShowModal} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <h4>Medicines Shown </h4>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Medicine</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            medShowData && medShowData.map((odr, i) => {
                                const med = meds.find(m => m.id == odr.medicines)
                                return (
                                    <tr key={i}>
                                        <td>{med.name}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => setMedShowModal(false)} variant="secondary">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    </div>
}

export default Schedules