import axios from "axios";

const offset = 15;

const auth = () => {
    return localStorage.getItem("token");
}

function configheaders() {
    return { headers: { "Authorization": `Token ${auth()}` } }
}

function view_datetime(datetime, dateonly=false) {
    let t_datetime = new Date(datetime);
    let dt = `${t_datetime.getDate()}/${t_datetime.getMonth() + 1}/${t_datetime.getFullYear()} `;
    if (dateonly) {
        return dt
    } else {
        return `${dt} ${t_datetime.getHours()}:${t_datetime.getMinutes().toString().padStart(2, '0')}`
    }
}

function loginApi(params) {
    let url = `${process.env.REACT_APP_API_LOCAL}login/`;
    return axios.post(url, params, {})
}

function AttendanceApi(params) {
    let url = `${process.env.REACT_APP_API_LOCAL}attendances/?${params}`;
    return axios.get(url, configheaders())
}

function MrListApi(action = "get", params = {}, page = 0) {
    let url = `${process.env.REACT_APP_API_LOCAL}medical-representatives/`;
    return action == 'create' ? axios.post(url, params, configheaders()) : action == 'get' ? axios.get(url + `?offset=${page}`, configheaders()) : action == 'delete' ? axios.delete(`${url}${params}/`, configheaders()) : action == 'put' ? axios.put(url + params.id + '/', params, configheaders()) : ""
}

function EntityApi(create = false, params = {}) {
    let url = `${process.env.REACT_APP_API_LOCAL}healthcare-entities/`;
    return create ? axios.post(url, params, configheaders()) : axios.get(url + `?${params}`, configheaders())
}

function CategoryApi(action = "get", params = {}) {
    let url = `${process.env.REACT_APP_API_LOCAL}categories/`;
    return action == "get" ? axios.get(url, configheaders()) : action == "create" ? axios.post(url, params, configheaders()) : action == 'delete' ? axios.delete(`${url}${params}/`, configheaders()) : ""
}

function AddMedApi(action = "get", data, cat_id = "") {
    let url = `${process.env.REACT_APP_API_LOCAL}medicines/`;
    return action == "get" ? axios.get(url + `?cat_id=${cat_id}`, configheaders()) : action == "create" ? axios.post(url, data, configheaders()) : action == 'delete' ? axios.delete(url + `${cat_id}/`, configheaders()) : action == "put" ? axios.put(url+`${cat_id}/`, data, configheaders()) : ""
}

function VisitsApi(create = false, params = {}, queryParams = "") {
    let url = `${process.env.REACT_APP_API_LOCAL}mr-visits/?${queryParams}/`;
    return create ? axios.post(url, params, configheaders()) : axios.get(url, configheaders())
}

function DashbaordApi() {
    let url = `${process.env.REACT_APP_API_LOCAL}admin-dashboard/`;
    return axios.get(url, configheaders())
}

function OrderApi(id, params) {
    let url = `${process.env.REACT_APP_API_LOCAL}order/${id}/`;
    return axios.post(url, params, configheaders())
}

export {
    offset,
    view_datetime,
    loginApi,
    AttendanceApi,
    MrListApi,
    EntityApi,
    CategoryApi,
    AddMedApi,
    VisitsApi,
    DashbaordApi,
    OrderApi
}