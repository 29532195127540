import React, { useState } from 'react';
import { Container, Form, Button, Card } from 'react-bootstrap';
import { loginApi } from '../services/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../common/Loader"

const Login = () => {
    const [data, setData] = useState();
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false)

    const handleLogin = (e) => {
        e.preventDefault();
            setLoading(true)
        loginApi(data).then(resp => {
            setLoading(false)
            localStorage.setItem("token", resp.data.token)
            toast.success("Login successfully.")
            navigate("/dashboard")
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data.error)
        })
    };

    return (
        <>
            {loading && <Loader />}
            <Container className='bg-lp' fluid>
                <div className='row flex-center min-vh-100 py-5 justify-content-center align-items-center'>
                    <div className='col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4'>
                        <div class="card">
                            <div className="card-header">
                                <div class="py-2 text-center">
                                    <span class="font-sans-serif fw-bolder fs-4 text-lp">Levotic Pharma - Admin</span>
                                </div>
                            </div>
                            <div class="card-body p-4 p-sm-5">
                                <Form onSubmit={handleLogin}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" placeholder="Enter username or email" required name="username"
                                            onChange={(e) => {
                                                setData({ ...data, [e.target.name]: e.target.value })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className='my-3' controlId="formPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" required name="password"
                                            onChange={(e) => {
                                                setData({ ...data, [e.target.name]: e.target.value })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Button variant="primary" type="submit" className='w-100'>
                                            Login
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Login