import { useEffect, useState } from "react";
import { EntityApi, offset } from "../services/services";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import PaginationComponent from "../common/Pagination";
import Loader from "../common/Loader"
import { useNavigate, Link } from "react-router-dom";
const Entities = () => {
    let [loading, setLoading] = useState(false)
    const [entities, setEntities] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [temp, setTemp] = useState(0);
    const [entityData, setEntityData] = useState({
        entity_type: '',
        name: '',
        location: '',
        phone: '',
        area: ''
    });
    const [filters, setFilters] = useState({
        type: "",
        search: ""
    });
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const types = [
        "doctor",
        "clinic",
        "hospital",
        "chemist"
    ]

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    useEffect(() => {
        getList();
    }, [temp])

    function getList() {
        setLoading(true)
        EntityApi(false, `offset=${(activePage - 1) * offset}&type=${filters.type}&search=${filters.search}`).then(resp => {
            setLoading(false)
            setTotalPage(resp.data.count / offset)
            setEntities(resp.data.results)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }

        })
    }

    function handleAddEntity(e) {
        e.preventDefault();
        setLoading(true)
        EntityApi(true, entityData).then(resp => {
            Swal.fire("Health Care Entity added successfully.", "", "success")
            setEntityData({ entity_type: '', name: '', location: '', phone: '', area: '' });
            handleClose();
            getList()
            setLoading(false)
        }).catch(err => {
            if (err.response.status == 401) {
                Swal.fire("Kindly log in to continue.", "", "warning")
                navigate("/")
            } else {
                Swal.fire("An error occured.", "", "error")
            }
        })
    }

    return (
        <div>
            {loading && <Loader />}
            <div className="row">
                <div className="col">
                    <h2>Health Care Entities</h2>
                </div>
                {/* <div className="col-auto">
                    <Button variant="success" onClick={handleShow}>
                        Add New Entity
                    </Button>
                </div> */}
            </div>
            <hr />

            <Row>
                <Col sm={12} md={12} className="mb-3">
                    <Row>
                        <h5>Filters</h5>
                        <Col sm={2} md={2}>
                            <Form.Group>
                                <Form.Select
                                    onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                                    name="type"
                                >
                                    <option value="">Filter by Type</option>
                                    {
                                        types.map((val, i) => (
                                            <option value={val} key={i}>{val.toUpperCase()}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col sm={5} md={5}>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Name, Area"
                                    value={filters.search}
                                    required
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group>
                                <Button onClick={() => setTemp(temp + 1)}>Search</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>

                <Col sm={12} md={12}>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Area</th>
                                <th>Contact Number</th>
                                <th>Added By</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entities.map((entity) => (
                                <tr key={entity.id}>
                                    <td>{entity.name}</td>
                                    <td>{entity.entity_type.toUpperCase()}</td>
                                    <td>{entity.area}</td>
                                    <td>{entity.phone}</td>
                                    <td>{entity.added_by?.user?.username}</td>
                                    <td>
                                        <Button variant="primary">
                                            Edit
                                        </Button>{' '}
                                        <Button variant="danger">
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <PaginationComponent currentPage={activePage} setActive={setActivePage} totalPages={totalPage} />

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Entity</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleAddEntity}>
                    <Modal.Body>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Entity Type</Form.Label>
                            <Form.Select
                                value={entityData.entity_type}
                                required
                                onChange={(e) => setEntityData({ ...entityData, entity_type: e.target.value })}
                            >
                                <option value="">Select Entity Type</option>
                                {
                                    types.map((val, i) => (
                                        <option value={val} key={i}>{val.toUpperCase()}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Entity Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter entity name"
                                value={entityData.name}
                                required
                                onChange={(e) => setEntityData({ ...entityData, name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter location"
                                required
                                value={entityData.location}
                                onChange={(e) => setEntityData({ ...entityData, location: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Area</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter area"
                                required
                                value={entityData.area}
                                onChange={(e) => setEntityData({ ...entityData, area: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter phone"
                                required
                                value={entityData.phone}
                                onChange={(e) => setEntityData({ ...entityData, phone: e.target.value })}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Add Entity
                            </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Form>
            </Modal>
        </div>
    )
}

export default Entities